<template>
  <div class="main">
    <CommonHeader/>
    <div class="title_section">
      アカウント設定
    </div>
    <div class="config_section">
      <div class="config_title">Kintoneアカウント</div>
      <div style="width: 100%;">
        <el-form ref="kintonForm" :model="kintonForm" label-width="150px" :rules="rules" label-position="left">
          <el-form-item label="URL" prop="kintoneUrl">
            <el-input v-model="kintonForm.kintoneUrl" maxlength="100" style="width: 70%;"></el-input>
          </el-form-item>
          <el-form-item label="管理者ユーザー" prop="kintoneUser">
            <el-input v-model="kintonForm.kintoneUser" maxlength="50" style="width: 70%;"></el-input>
          </el-form-item>
          <el-form-item label="管理者パスワード" prop="kintonePwd">
            <el-input v-model="kintonForm.kintonePwd" maxlength="50" style="width: 70%;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('kintonForm')">{{$t('account.update')}}</el-button>
            <el-button @click="resetForm()">{{$t('account.cancel')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="config_section">
      <div class="config_title">メール送受信アカウント</div>
      <el-table
      :data="mailList" width="100%">
        <el-table-column
          label="ID"
          width="80px">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id }}
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="メールアドレス">
          <template slot-scope="scope">
            <span>
              {{ scope.row.account }}
            </span><br>
            <span v-if="scope.row.deleted==1">
              <el-tag type="info" size="mini">無効</el-tag>
            </span>
            <span v-if="scope.row.deleted==0">
              <el-tag type="success" size="mini">有効</el-tag>
            </span>
          </template>
          
        </el-table-column>
        <el-table-column
          label="種別"
          width="180px">
          <template slot-scope="scope">
            <span v-if="scope.row.protocol==1">
              POP3
            </span>
            <span v-if="scope.row.protocol==2">
              IMAP
            </span>
          </template>
          
        </el-table-column>
        
        <el-table-column
          label="作成時間"
          width="180px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.createTime }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="160px">
          <template slot-scope="scope">
          <el-button size="small" type="" @click="handleUpdate(scope.row)">設定</el-button>
          <el-button size="small" type="" @click="handleDelete(scope.row.id)">削除</el-button>
        </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="margin-top: 18px;" @click="dialogFormVisible = true">追加</el-button>
    </div>
    <el-dialog title="メールアカウント" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-width="150px" label-position="left">
        <el-form-item label="アカウント" >
          <el-input v-model="form.account" autocomplete="off" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="パスワード" >
          <el-input v-model="form.password" autocomplete="off" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="種別" l>
          <el-radio-group v-model="form.protocol">
            <el-radio :label="1">POP3</el-radio>
            <el-radio :label="2">IMAP</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="POP3" >
          <el-input v-model="form.pop3" autocomplete="off" style="width: 70%;" :disabled="form.protocol != 1"></el-input>
        </el-form-item>
        <el-form-item label="IMAP" >
          <el-input v-model="form.imap" autocomplete="off" style="width: 70%;" :disabled="form.protocol != 2"></el-input>
        </el-form-item>
        <el-form-item label="SMTP">
          <el-input v-model="form.smtp" autocomplete="off" style="width: 70%;" ></el-input>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="handleAdd">確認</el-button>
      </div>
    </el-dialog>
    <CommonFooter/>
  </div>
</template>
<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";

import { isValidResponse } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import { mapState } from "vuex";
import { getUserInfo, update, updateMail, deleteMail } from "@/api/account";

export default {
  name: "DocList",
  components: {
    CommonHeader,
    CommonFooter
  },
  data: () => {
    return {
      kintonForm:{
        kintoneUrl:"",
        kintoneUser:"",
        kintonePwd:""
      },
      kintonFormOrigin:{},
      form:{},
      dialogFormVisible: false,
      rules: {
        kintoneUrl: [
          { required: true, message: "KintoneのURLを入力してください。", trigger: "blur" },
        ],
        kintoneUser: [
          { required: true, message: "Kintone管理者のユーザー名を入力してください。", trigger: "blur" },
        ]
      },
      mailList:[]
    };
  },
  mounted() {
    this.refreshData();
  },
  created: function () {
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
  methods: {
    handleUpdate(row) {
      this.form = {...row};
      this.dialogFormVisible = true;

    },
    handleAdd() {
      updateMail({
        id:this.accountInfo.id,
        mails:[{...this.form}]
      }).then(response => {
        if(isValidResponse(response)){
          this.refreshData();
          this.$message({
            message: "設定しました",
            type: "success"
          });
        }
        this.dialogFormVisible = false;
        this.form = {};
      });
    },
    handleDelete(id) {
      deleteMail({
        id:id
      }).then(response => {
        if(isValidResponse(response)){
          this.refreshData();
          this.$message({
            message: "削除しました",
            type: "success"
          });
        }
      });
    },
    refreshData() {
      getUserInfo({
      }).then(response => {
        this.kintonFormOrigin = response.data;
        this.kintonForm = { ...response.data };
        this.mailList = response.data.mails;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          update({
            ...this.kintonForm
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            this.refreshData();
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.form = { ...this.kintonFormOrigin };
    }
    
  },
};
</script>
<style scoped>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title_section {
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  width: 1060px;
  margin-top: 32px;
  font-size: 22px;
  font-weight: bold;
}
.config_section {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content:flex-start;
  background-color: #fff;
  width: 1024px;
  margin: 16px 0px;
  padding: 36px;
  border-radius: 6px;
}
.config_title {
  width: 1024px;
  font-size: 18px;
  text-align: left;
  margin-bottom: 36px;
}


</style>

